module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@1.13.3_styled-component_ziqzoeyvnz5n44o2yqywdaecvm/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@1.2.3_gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_3gqaxlft3ofjmyjy34aivbs6qq/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en","pt"],"defaultLanguage":"es","siteUrl":"empresas.lapieza.io","i18nextOptions":{"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:ui","getLanguageFromPath":true},{"matchPath":"/preview","languages":["es"]}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__react-dom@17.0_6xem646fagzq2boryd5lrkhx4m/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"ATS LaPieza","start_url":"/","display":"standalone","icon":"./src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aea9219ffa860ccceff66b0bfcdaffc8"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-crisp-chat@3.2.2_gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__react-dom@17._6ppm7vnqlu5s7c46kjaujti7pi/node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"04e5e200-dc66-4f52-b21c-d20935f55213","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"445060524041354"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_5bcutb5cginn4fl56z7ekcubea/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y72HFSYQGQ"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__react-dom@17.0.2_react@17.0.2__react@17.0.2__3j7qlqqqngeqg2txd63mb5h4ha/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
